<template>
	<div style="background-color: #EFF3FC;height: 100vh;">
		<div class="container">
			<div class="row" style="background-color: #EFF3FC;height: 100vh;">
				<div class="col-7 m-auto bg-white rounded-lg shadow-sm">
					<div class="row">
						<div class="col-7 m-auto">
							<img src="../../assets/login.png" class="img-fluid" />
						</div>
						<div class="col-5 m-auto">
							<div class="card border-0 pt-5 pb-3">
								<div class="card-header text-center bg-white border-0">
									<h6 class="m-0 text-secondary font-weight-bolder">{{$conf.systemName}}</h6>
								</div>
								<div class="card-body">
									<el-form ref="form" :model="form" :rules="rules">
										<el-form-item prop="username">
											<el-input v-model="form.username" placeholder="请输入用户名"
												prefix-icon="el-icon-user">
											</el-input>
										</el-form-item>
										<el-form-item prop="password">
											<el-input v-model="form.password" placeholder="请输入密码" type="password"
												prefix-icon="el-icon-lock">
											</el-input>
										</el-form-item>
										<el-form-item>
											<el-button type="primary" @click="onSubmit('form')" :loading="loading" class="w-100 shadow-sm">
												{{loading?'登录中...':'立即登录'}}
											</el-button>
										</el-form-item>
									</el-form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				loading:false,
				form: {
					username: '',
					password: ''
				},
				rules: {
					username: [{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					}, ],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}, ]
				}
			}
		},
		methods: {
			onSubmit(formName) {
				this.$refs[formName].validate((valid) => {
					if (!valid) return;
					this.loading = true
					this.axios.post('/admin/admin/login', this.form)
						.then((res)=>{
							let data = res.data.data
							//存储登录信息到本地存储
							this.$store.commit('login', data)
							//存储权限
							if(data.role && data.role.rules) {
								window.sessionStorage.setItem('rules',JSON.stringify(data.role.rules))
							}
							//生成菜单
							this.$store.commit('createMenu',data.tree)
							//登录成功提示
							this.$message({
								message: '登录成功',
								type: 'success'
							});
							this.loading = false
							//初始化导航
							localStorage.setItem('navActive', JSON.stringify({
								top: '0',
								left: '0'
							}))
							//管理员跳转首页，其他用户跳转列表
							let findIndex = data.role.rules.find(item => item.frontpath == 'index/index')
							if(findIndex){
								this.$router.push({
									name: 'index'
								})
							}else{
								this.$router.push({
									name: 'service_roughInspect_list'
								})
							}
							
						}).catch((error)=>{
							this.loading = false
						})
				});
			},
		}
	}
</script>

<style>
</style>
